<!--
 * @Author: 张博洋
 * @Date: 2021-08-10 18:46:06
 * @LastEditTime: 2021-12-17 10:28:48
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/patient/admin.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="list">
    <div class="top">
      <div v-if="list.length">
        <!-- <div class="patient-item"
             v-for="(item,index) in list"
             :key="index"
             @click="checkPatient($event,item)">
          <div class="info">
            <span class="name">{{item.patientName}}</span>
            <span class="sex ml12">{{calcSex(item.sex)}}</span>
            <span class="age ml12">{{item.age ? item.age + '岁' : ''}}</span>
          </div>
          <div class="tel mt12">手机号：{{item.phone}}</div>
          <div class="id mt4">证件号：{{item.idCardNum}}</div>
          <div class="edit"
               ref="edit"
               @click="edit(item)">
            编辑
          </div>
        </div> -->

        <van-swipe-cell class="patient-item"
                        v-for="(item,index) in list"
                        :key="index">
          <div @click="checkPatient($event,item)">
            <div class="info mt10 ml10">
              <span class="name mr12">{{item.patientName}}</span>
              <div>
                <span class="sex">{{calcSex(item.sex)}}</span>
                <span class="age ml12">{{item.age ? item.age + '岁' : ''}}</span>
                <span class="def ml12"
                      v-if="item.setDefault">默认就诊人</span>
              </div>

            </div>
            <div class="tel mt12 ml10">手机号：{{item.phone}}</div>
            <div class="id mt4 ml10 mb10">证件号：{{item.idCardNum}}</div>
          </div>

          <template #right>
            <van-button @click="edit(item)"
                        square
                        text="编辑"
                        color="rgba(26, 179, 112, 1)"
                        class="delete-button" />
          </template>
        </van-swipe-cell>

        <p class="tip">温馨提示 <br>（1）每个账号最多可以绑定10个就诊人，暂不允许解绑，谨慎操作。 <br>（2）请确保所填全部信息真实准确，否则将导致业务无法办理，因此带来的损失由本人自行承担。</p>
      </div>

      <div v-if="!list.length"
           class="empty">
        <img width="120"
             height="120"
             src="@/static/img/patient_empty.svg"
             alt="">
        <p>暂无就诊人</p>
      </div>

    </div>

    <div class="btn-group mt8">
      <van-button class="add"
                  icon="plus"
                  to="/patient/add"
                  round>添加就诊人</van-button>

    </div>
  </div>
</template>

<script>
import SwipeCell from '@/mixin/SwipeCell.js'
export default {
  mixins: [SwipeCell],
  data() {
    return {
      list: [],
    }
  },
  activated() {
    this.getPatientList()
  },
  created() {
    this.getPatientList()
  },
  methods: {
    calcSex(sex) {
      if (sex === 1) {
        return '男'
      } else if (sex === 2) {
        return '女'
      } else {
        return ''
      }
    },
    getPatientList() {
      this.$axios({
        url: 'healthy/getPatients',
        _self: this,
      }).then((res) => {
        console.log(res)
        this.list = res.d.data
      })
    },

    checkPatient(e, item) {
      // if (e.target.alt && e.target.alt === '编辑') return
      this.$router.push({
        name: 'patient.detail',
        query: {
          id: item.patientId,
        },
      })
    },
    edit(item) {
      this.$router.push({
        name: 'patient.edit',
        query: {
          id: item.patientId,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';
.list {
  height: 100%;
  display: flex;
  flex-direction: column;
  .top {
    flex: 1;
    overflow-y: auto;
    .empty {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      p {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
    }
  }
}
.patient-item {
  margin: 8px 8px 0 8px;
  background: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  .info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .name {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
    }
    .sex,
    .age {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
    }
    .def {
      font-size: 12px;
      font-weight: 400;
      color: #1ab370;
      text-align: center;
      border-radius: 2px;
      border: 1px solid #1ab370;
      padding: 0px 4px;
    }
  }
  .tel,
  .id {
    font-size: 14px;
    font-weight: 400;
    color: #999;
  }

  .edit {
    width: 40px;
    height: 100%;
    color: #fff;
    background: rgba(26, 179, 112, 1);
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 17px;
    }
  }
  .van-swipe-cell__right {
    .van-button {
      height: 100%;
    }
  }
}
.tip {
  padding: 12px 16px;
  font-size: 12px;
  font-weight: 400;
  color: #999999;
}
.btn-group {
  // position: fixed;
  // bottom: 0;
  width: 100%;
  display: flex;
  padding: 10px 16px 30px 16px;
  background: #ffffff;
  .add {
    width: 100%;
    flex: 1;
    background: color(primary) !important;
    color: #fff;
  }
}
</style>